import {Nav, Navbar} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import React from "react";

export default function PageHeader(props) {

  const navigate = useNavigate();
  let title = props.title ? props.title : 'Empty Page';
  let search = props.search;

  return (
    <Navbar sticky='top' className='page-header'>
      <Nav>
        <Nav.Link onClick={() => navigate(-1)}>
          <img src="/navigation-back-arrow.svg" alt='Go Back' />
        </Nav.Link>
        <Navbar.Collapse className={`justify-content-center ${ !!search ? 'with-search' : '' }`}>
          <Navbar.Text>{ title }</Navbar.Text>
        </Navbar.Collapse>
        { !!search && (
          <Navbar.Text className='right'>
            <a href="#do-search" onClick={props.search}><img width='30' src="/search_icon.png" alt='Search' /></a>
          </Navbar.Text>
        ) }
        <Navbar.Text className='right'>
          <Link to='/'>
            <img alt='Home' src="/nl-corner.png"/>
          </Link>
        </Navbar.Text>
      </Nav>
    </Navbar>
  );

}
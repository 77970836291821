
import ArtistList from "../components/ArtistList";
import {Button, Form, Stack} from "react-bootstrap";
import PageHeader from "../components/PageHeader";
import React, {useEffect, useState} from "react";

export default function NlPerformers() {

  const [state, setState] = useState({
    searchTerm: '',
    visible: false,
    isShowing: false,
  });

  let formControl = null;

  function showSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    setState({
      ...state,
      visible: true,
      isShowing: true,
    });
  }

  useEffect(() => {
    if (state.isShowing && state.visible) {
      formControl.focus();
      setState({
        ...state,
        isShowing: false,
      });
    }
  }, [state, formControl]);

  function closeSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    formControl.value = '';
    setState({
      ...state,
      searchTerm: '',
      visible: false,
    });
  }

  function setSearchTerm(event) {
    window.scrollTo(0, 0);
    setState({
      ...state,
      searchTerm: event.currentTarget.value,
    })
  }

  return (
    <div className="nl-performers">
      <PageHeader title='Performers' search={showSearch} />
      <Stack hidden={!state.visible} direction="horizontal" gap={1} className="search-field sticky-top">
        {/*<Form.Label className='mt-2'>Search</Form.Label>*/}
        <Form.Control ref={element => formControl = element} onChange={setSearchTerm} id="searchTerm" type="text" placeholder="Enter search term" />
        <Button variant="secondary" onClick={closeSearch}><b>X</b></Button>
      </Stack>
      <div>
        <ArtistList searchTerm={state.searchTerm} />
      </div>
    </div>
  );

}

import {Card, Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import React, {useEffect} from "react";

export default function NlHome() {

  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add('nl-page-home');
    return () => {
      // and remove it when departing
      document.body.classList.remove("nl-page-home");
    };
  });

  return (
    <div className="nl-home nl-gutter">
      <Navbar collapseOnSelect={true} expand={false}>
        <Navbar.Toggle aria-controls='nl-nav'/>
        <Navbar.Offcanvas id='nl-nav'>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <img src="/nl-corner.png" alt='Newstead Live 2024' />
              Newstead Live 2024
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav>
              <Nav.Link href="/performers">Performers</Nav.Link>
              <Nav.Link href="/schedule">Set Times</Nav.Link>
              <Nav.Link href="/venues">Venues</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
              <Nav.Link href="/faq">Useful Info</Nav.Link>
              <Nav.Link href="/favourites">★ Favourites</Nav.Link>
              {/*<Nav.Link href="https://www.newsteadlive.com/tickets-1" target="_blank">Buy Tickets</Nav.Link>*/}
              <Nav.Link href="https://www.newsteadlive.com/" target="_blank">Main Web Site</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        <Navbar.Brand>
          <div className='logo'>
            <img src="/NL_Logo2020.png" alt='Newstead Live 2024'/>
          </div>
        </Navbar.Brand>
      </Navbar>
      <div className='cards'>
        <Card>
          <Link to="/performers">
            <Card.Body>
              <Card.Title>Performers</Card.Title>
            </Card.Body>
          </Link>
        </Card>
        <Card>
          <Link to="/schedule">
            <Card.Body>
              <Card.Title>Set Times</Card.Title>
            </Card.Body>
          </Link>
        </Card>
        <Card>
          <Link to="/venues">
            <Card.Body>
              <Card.Title>Venues</Card.Title>
            </Card.Body>
          </Link>
        </Card>
        <Card>
          <Link to="/faq">
            <Card.Body>
              <Card.Title>Useful Info</Card.Title>
            </Card.Body>
          </Link>
        </Card>
      </div>

    </div>
  );
}

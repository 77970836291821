
export const SvgMapMarker = (
  <span className='svgMapMarker'>
    <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 512 512">
      <g>
        <path className="st0" d="M256,0C159.969,0,82.109,77.859,82.109,173.906c0,100.719,80.016,163.688,123.297,238.719
              C246.813,484.406,246.781,512,256,512s9.188-27.594,50.594-99.375c43.297-75.031,123.297-138,123.297-238.719
              C429.891,77.859,352.031,0,256,0z M256,240.406c-36.734,0-66.516-29.781-66.516-66.5c0-36.75,29.781-66.531,66.516-66.531
              s66.516,29.781,66.516,66.531C322.516,210.625,292.734,240.406,256,240.406z" />
      </g>
    </svg>
  </span>
);

export const SvgClock = (
  <span className='svgClock'>
    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle className="st0"  cx="12" cy="12" r="8.5" />
      <path className="st0"  d="M12 7V12L14.8 14.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </span>
);

export const SvgHistory = (
  <span className='svgHistory'>
    <svg preserveAspectRatio="none" data-bbox="28.967 5.79 142.065 189.419" viewBox="28.967 5.79 142.065 189.419" xmlns="http://www.w3.org/2000/svg" role="presentation" aria-hidden="true">
      <g>
        <path d="M168.177 64.984a2.855 2.855 0 0 1 2.855 2.855v20.822c0 35.083-25.472 64.151-58.905 69.916a.346.346 0 0 0-.289.341v24.099c0 .196.159.354.354.354h17.404a5.916 5.916 0 0 1 5.919 5.919 5.911 5.911 0 0 1-5.919 5.919H70.403a5.913 5.913 0 0 1-5.919-5.919 5.918 5.918 0 0 1 5.919-5.919h17.404a.354.354 0 0 0 .354-.354v-24.099a.346.346 0 0 0-.289-.341c-33.432-5.765-58.905-34.832-58.905-69.916V67.839a2.855 2.855 0 0 1 2.855-2.855h6.128a2.855 2.855 0 0 1 2.855 2.855v20.822c-.266 21.318 10.964 41.15 29.388 51.879a59.21 59.21 0 0 0 59.622 0c18.413-10.729 29.643-30.561 29.378-51.879V67.839a2.855 2.855 0 0 1 2.855-2.855h6.129zm-63.838 58.936c18.102-2.146 31.177-18.522 31.177-36.751V42.805c0-18.23-13.074-34.608-31.177-36.757-21.54-2.557-39.856 14.221-39.856 35.258v47.355c.001 21.053 18.316 37.813 39.856 35.259z"></path>
      </g>
    </svg>
  </span>
);

export const SvgAccommodation = (
  <span className='svgAccommodation'>
    <svg preserveAspectRatio="none" data-bbox="25.88 22.807 148.241 148.24" xmlns="http://www.w3.org/2000/svg" viewBox="25.88 22.807 148.241 148.24" role="presentation" aria-hidden="true">
      <g>
        <path d="M165.428 125.128c-7.692-2.163-12.444-9.738-12.444-34.646 0-25.895-17.984-47.542-42.135-53.258h.153c.063-.5.088-1.01.088-1.526 0-7.119-5.765-12.891-12.89-12.891-7.112 0-12.89 5.772-12.89 12.891 0 .516.038 1.026.088 1.51h.217c-24.176 5.695-42.199 27.357-42.199 53.274 0 24.899-2.94 32.483-10.644 34.646-4.595 1.293-6.892 5.491-6.892 10.272v16.311h52.984c0 10.674 8.661 19.336 19.336 19.336 10.675 0 19.336-8.662 19.336-19.336h56.585v-16.315c-.001-4.786-4.086-8.975-8.693-10.268z"></path>
      </g>
    </svg>
  </span>
);

export const SvgOther = (
  <span className='svgOther'>
    <svg preserveAspectRatio="xMidYMid meet" data-bbox="0 0 353.684 336.37" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 353.684 336.37" role="presentation" aria-hidden="true">
      <g>
        <path d="M176.842 0l54.634 110.714 122.208 17.77-88.432 86.194 20.877 121.692-109.287-57.449L67.535 336.37l20.876-121.692L0 128.484l122.189-17.77L176.842 0z"></path>
      </g>
    </svg>
  </span>
);

export const SvgMap2 = (
  <span className='svgMap2'>
    <svg preserveAspectRatio="xMidYMid meet" data-bbox="38.924 16.62 123.854 165.137" xmlns="http://www.w3.org/2000/svg" viewBox="38.924 16.62 123.854 165.137" role="presentation" aria-hidden="true">
      <g>
        <path d="M100.785 16.62c-34.193.036-61.897 27.791-61.861 61.989.036 34.204 62.035 103.148 62.035 103.148s61.855-69.074 61.819-103.278c-.036-34.199-27.796-61.895-61.993-61.859zm.093 87.724c-17.098.018-30.972-13.83-30.99-30.929-.018-17.098 13.831-30.968 30.925-30.986 17.103-.018 30.976 13.823 30.994 30.921.018 17.099-13.827 30.976-30.929 30.994z"></path>
      </g>
    </svg>
  </span>
);

export const SvgFaq = (
  <span className='svnFaq'>
    <svg width="800px" height="800px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <g id="Layer_2" data-name="Layer 2">
        <g id="invisible_box" data-name="invisible box">
          <rect width="48" height="48" fill="none"/>
        </g>
        <g id="icons_Q2" data-name="icons Q2">
          <path d="M24,2A22,22,0,1,0,46,24,21.9,21.9,0,0,0,24,2Zm0,40A18,18,0,1,1,42,24,18.1,18.1,0,0,1,24,42Z"/>
          <path d="M24,20a2,2,0,0,0-2,2V34a2,2,0,0,0,4,0V22A2,2,0,0,0,24,20Z"/>
          <circle cx="24" cy="14" r="2"/>
        </g>
      </g>
    </svg>
  </span>
);
import React, {createRef, useEffect, useState} from "react";
import {useDraggable} from "react-use-draggable-scroll";

export default function Map(props) {

  let slug = !!props.venue ? props.venue : 'Newstead';

  const mapRef = createRef();
  const imgRef = createRef();
  const butPlus = createRef();
  const butMinus = createRef();

  const { events } = useDraggable(mapRef, {
    applyRubberBandEffect: true,
  });

  const min = 150;
  const max = 400;

  // const evCache = [];
  // const prevDiff = -1;

  var z = 300;
  if (document.body.clientWidth < 500) {
    z = z + 50;
    // console.log('New Zoom: ' + z);
  }


  const params = {
    'Newstead': { zoom: 100, top: 0, left: 0 },
    'festival-village': { zoom: z, top: 0.1, left: .250 },
    'troubadour': { zoom: z, top: .33, left: .442 },
    'community-centre': { zoom: z, top: 0.1, left: .250 },
    'lilliput': { zoom: z, top: 0.1, left: .250 },
    'anglican-church': { zoom: z, top: .33, left: .442 },
    'enviroshop': { zoom: z, top: 0, left: .666 },
    'playground-park': { zoom: z, top: 0.1, left: .250 },
    'live-n-local': { zoom: z, top: 0.1, left: .250 },
    'makers-gallery': { zoom: z, top: 0.6785, left: 0 },
    'pool-party': { zoom: z, top: 0.1, left: .250 },
  };

  // console.log(slug, params[slug]);

  // const [state, setState] = useState({
  const [state] = useState({
    zoom: props.zoom || params[slug].zoom,
    top: props.top || params[slug].top,
    left: props.left || params[slug].left
  });

  const zoomUp = () => {
    let zoom = parseInt(imgRef.current.style.width);
    if (zoom < max) {
      zoom += 25;
      imgRef.current.style.width = zoom + '%';
    }
    butPlus.current.disabled = (zoom === max);
    butMinus.current.disabled = (zoom === min);
/*
    Can't change the fucking state or the fucking drag component fucks up completely 😡
    if (state.zoom < max) {
      setState({
        ...state,
        zoom: state.zoom + 25,
      });
    }
*/
  }

  const zoomDown = () => {
    let zoom = parseInt(imgRef.current.style.width);
    if (zoom > min) {
      zoom -= 25;
      imgRef.current.style.width = zoom + '%';
    }
    butPlus.current.disabled = (zoom === max);
    butMinus.current.disabled = (zoom === min);
  }

  useEffect(() => {

    setTimeout(()=>{
      let top = state.top;
      let height = imgRef.current.clientHeight;
      let left = state.left;
      let width = imgRef.current.clientWidth;

      // This shit is fucking killing me. For whatever reason, sometimes the fucking height is always zero.
      // So calculate it from the width 😩
      if (height === 0) {
        height = width * 0.847395833333333;
      }

      // console.log('=== USE EFFECT ===');
      // console.log('Top: ' + top + '; Left: ' + left);
      if (document.body.clientWidth < 500) {
        top = top - 0.1;
        left = left + 0.01;
        // console.log('New Top: ' + top + '; New left: ' + left);
      }
      // console.log('Height: ' + height + '; Width: ' + width);
      let scrollTop = top * height;
      let scrollLeft = left * width;
      // console.log('ScrollTop: ' + scrollTop + '; ScrollLeft: ' + scrollLeft);
      // console.log('=== END ===');

      mapRef.current.scrollTop = scrollTop;
      mapRef.current.scrollLeft = scrollLeft;
      imgRef.current.classList.remove('dim');

    }, 100);

  }, [imgRef, mapRef, state]);

  return (
    <div className='static-map'>
      <div className='map-controls'>

{/*
        <button onClick={()=>{
          let top = mapRef.current.scrollTop;
          let height = imgRef.current.clientHeight;
          let left = mapRef.current.scrollLeft;
          let width = imgRef.current.clientWidth;
          console.log(mapRef);
          console.log('Top: ' + top + '; Left: ' + left);
          console.log('Height: ' + height + '; Width: ' + width);
          console.log('Top: ' + top/height + '; Left: ' + left/width);
        }}>Co-ords</button>
*/}

        <button className='plus' ref={butPlus} onClick={zoomUp}>+</button>
        <button className='minus' ref={butMinus} onClick={zoomDown}>-</button>
      </div>
      <div
        {...events}
        ref={mapRef}
        className='img-map'
      >
        {/*<img ref={imgRef} src='/nsl23-map.png' style={ { width: `${state.zoom}%`, opacity: 0.05 } } />*/}
        <img ref={imgRef} src='/nsl23-map.png' alt="Newstead Live Map" style={ { width: `${params[slug].zoom}%` } } className='dim' />
      </div>
    </div>
  );

  // Old - Google maps
  /*
  if (slug === 'Newstead') {
    return (<iframe title={slug} width="100%" height="400" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Newstead,%20Victoria,%20Australia+(Newstead)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />);
  } else if (slug === 'troubadour') {
    return (<iframe title={slug} width="100%" height="400" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Cricket+%26+Recreation+Reserve+(Newstead),%20Newstead%20VIC%203462,%20Australia+(The%20Troubadour)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />);
  } else if (slug === 'live-n-local') {
    return (<iframe title={slug} width="100%" height="400" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=2%20Panmure%20St,%20Newstead%20VIC,%20Australia+(Live%20'n%20Local%20Stage)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />);
  } else if (slug === 'community-centre') {
    return (<iframe title={slug} width="100%" height="400" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=9%20Lyons%20St,%20Newstead%20VIC,%20Australia+(Newstead%20Community%20Centre)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />);
  } else if (slug === 'playground-park') {
    return (<iframe title={slug} width="100%" height="400" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Newstead%20Playground,%204%20Pyrenees%20Hwy,%20Newstead%20VIC%203462,%20Australia+(Shedshaker%20Stage%20&amp;%20Bar)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />);
  } else if (slug === 'lilliput') {
    return (<iframe title={slug} width="100%" height="400" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=2%20Panmure%20Street,%20Newstead%203462%20VIC%20Australia+(Lilliput)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />);
  } else if (slug === 'enviroshop') {
    return (<iframe title={slug} width="100%" height="400" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=49%20Lyons%20St,%20Newstead%20VIC,%20Australia+(Enviroshop)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />);
  } else if (slug === 'anglican-church') {
    return (<iframe title={slug} width="100%" height="400" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=26%20Panmure%20St,%20Newstead%20VIC,%20Australia+(Anglican%20Church)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />);
  } else if (slug === 'makers-gallery') {
    return (<iframe title={slug} width="100%" height="400" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Newstead%20Arts%20Hub,%208A%20Tivey%20St,%20Newstead%20VIC%203462+(Newstead%20Arts%20Hub)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />);
  }
  */

}
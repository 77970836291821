import {useLoaderData} from "react-router-dom";

import {Card} from "react-bootstrap";

import performers from '../json/performers.json';
import ScheduleList from "../components/ScheduleList";
import PageHeader from "../components/PageHeader";
import {useCallback, useEffect, useState} from "react";
import {getLsFavourites, setLsFavourites, toggleFaveArtist} from "../json/favourites";

export async function performerLoader({params}) {
  let performer = null;
  let slug = encodeURIComponent(params.slug);

  performers.map((item) => {
    if (item.slug === slug) {
      performer = item;
    }
    return performer;
  });

  return performer;
}

export default function NlPerformerPage() {

  const performer = useLoaderData();

  // Faves state from local storage
  const [lsFaves, setFaves] = useState(getLsFavourites());

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Update local storage on lsFaves change
  useEffect(() => {
    setLsFavourites(lsFaves);
  }, [lsFaves]);

  // Favourites button click => toggle as fave
  const faveArtist = useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
    let slug = event.currentTarget.dataset.slug;
    // console.log('faveArtist', event.currentTarget, slug);
    setFaves({
      events: lsFaves.events,
      artists: toggleFaveArtist(lsFaves.artists, slug)
    });
  }, [lsFaves]);

  return !!performer && (
    <div className="nl-performer-page">
      <PageHeader title={performer.title}/>
      <Card>
        <Card.Img src={performer.image}></Card.Img>
        <Card.Title>
          <div className='performer-title col-11'>
            {performer.title}
            { !!performer.byline && (<div className='performer-byline'>{performer.byline}</div>) }
          </div>
          <div className='p-fave col-1'>
            <a
              href='#do-fav'
              className='fav-button'
              data-slug={performer.slug}
              onClick={faveArtist}
              title={lsFaves.artists.includes(performer.slug) ? 'Remove Favourite' : 'Add Favourite'}
            > {lsFaves.artists.includes(performer.slug) ? '★' : '☆'}</a>
          </div>
        </Card.Title>
        <Card.Body>{performer.bio}</Card.Body>
      </Card>
      <ScheduleList usePerformer={performer.slug}/>
    </div>
  );

}


import React from "react";
import BreakoutBox from "../components/BreakoutBox";
import PageHeader from "../components/PageHeader";
import {SvgHistory, SvgMap2} from "../components/svg";
// import Map from "../components/Map";

export default function NlAbout() {

  return (
    <div className='nl-about-page'>
      <PageHeader title='About' />

      <p className='page-callout'>Australian folk-roots music festival in the historic country town of Newstead, near Castlemaine, Central Victoria.</p>

      <BreakoutBox
        svg={SvgHistory}
        title='About Us'
        contents={
          <>
            <p>Newstead Live Music Festival has been running for over twenty years, the first six years as Chewton Folk Festival.</p>
            <p>The festival takes place over the last weekend in January, when a range of diverse and highly regarded international and national artists perform in live music venues, concert spaces, workshops, impromptu sessions, spoken word performances and open-mic sessions. Music for all ages and a range of genres.</p>
            <p>Newstead Live is managed by a voluntary body of members working together to develop their community.</p>
          </>
        }
      />

      <BreakoutBox
        svg={SvgMap2}
        title="Getting There"
        contents={
          <>
            <p>Newstead is a thriving, vibrant town on the Loddon River in Central Victoria, 15 minutes from Castlemaine and about 1.5 hours north-west of Melbourne.</p>
            <p><a href="https://www.ptv.vic.gov.au/stop/view/20301/" target="_blank" rel='noreferrer'>Details on trains to Castlemaine</a></p>
            <p><a href="http://newstead.vic.au/service/public-transport" target="_blank" rel='noreferrer'>Details on public transport to Newstead</a></p>
            <p><a href="https://goo.gl/maps/FD9pzhtgfr3w2Zbg6" target="_blank" rel='noreferrer'>Get driving directions</a></p>
{/*
            <div>
              <Map />
            </div>
*/}
          </>
        }
      />
{/*
      <BreakoutBox
        svg={SvgAccommodation}
        title="Accommodation"
        contents={
          <>
            <p className='action-button'><a href="https://events.humanitix.com.au/newstead-live-music-festival-2020" target="_blank" rel='noreferrer'>BOOK NOW</a></p>

            <h4>Camping</h4>

            <p>There are two campgrounds available during the festival:</p>

            <h5>The Oval</h5>
            <p>
              Located at the Recreation Reserve, where The Troubadour is based. You must have an adult weekend ticket in order to book a site. There are basic facilities and there is no electricity. There are only a limited number of sites available. You must book and pay before the start of the festival.
              Cost is $45 for a single site and $70 for a double site for the weekend.
            </p>

            <h5>The Racecourse Reserve</h5>
            <p>
              This is a little way out of town. There are shower and toilet facilities. There is no electricity.
              Cost is $10 per night per couple, then $5 for each additional adult.
              There is a shuttle bus that runs between the campground and the venues in the township. Pay on entry to the reserve.
              NOTE: YOU WILL NEED CASH FOR THIS!
            </p>

            <h4>Other Accommodation</h4>
            <p><a
              href="http://www.maldoncastlemaine.com.au/surrounding-towns/newstead/newstead-accommodation/"
              target="_blank" rel="noreferrer noopener">Click here</a> for other accommodation options in the area.
            </p>
          </>
        }
      />
*/}
{/*
      <BreakoutBox
        svg={SvgOther}
        title='Other information'
        contents={
          <div>
            <h4>Food &amp; Drink</h4>
            <p>There are numerous options available in town during the festival; The Crown Hotel, Dig Cafe, Supermarket &amp; Bakery, The Country Store (eat in or take away), The Troubadour Wine Bar, Thai Cafe &amp; Continental Breakfast at the Community Centre, and food vans in the Festival Village</p>

            <h4>Shuttle Bus</h4>
            <p>Shuttle bus runs between the campground and the venues in the township.</p>

            <h4>ATM</h4>
            <p>There is no ATM in Newstead. All businesses in our small town take cards but please note small businesses do not have the capacity to give cash out. Please bring cash if you need it.</p>

            <h4>What to Bring</h4>
            <p>A sense of humour, your dancing shoes, togs, towel, swag, musical instrument and a bottle of Buckfast. The rest will look after itself.</p>
          </div>
        }
      />
*/}
    </div>
  );

}
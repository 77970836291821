import React from "react";

export default function BreakoutBox(props) {

  return (
    <div className='breakout-box'>
      <div className='header'>
        <div className='svg'>
          {props.svg}
          <div className='divider' />
        </div>
        <div className='title'><h3>{props.title}</h3></div>
      </div>
      <div className='contents'>
        {props.contents}
      </div>
    </div>
  );

}
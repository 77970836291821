import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({

  onUpdate: (registration) => {
    // console.log('Update found for registration: ', registration);

    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.onstatechange = (event) => {
        // console.log('WSW state: ', event.target.state);
      }
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },


});

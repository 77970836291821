
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ArtistList from "../components/ArtistList";
import ScheduleList from "../components/ScheduleList";
import PageHeader from "../components/PageHeader";

export default function NlFaves() {

  return (
    <div className="nl-favourites">
      <PageHeader title='My Favourites' />
      <Tabs
        defaultActiveKey="events"
        id="favourites-tabs"
      >
        <Tab eventKey="events" title="Set Times">
          <ScheduleList showFavourites={true} />
        </Tab>
        <Tab eventKey="performers" title="Performers">
          <ArtistList showFavourites={true} />
        </Tab>
      </Tabs>
    </div>
  );
}

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ScheduleList from "../components/ScheduleList";
import PageHeader from "../components/PageHeader";
import {useCallback} from "react";

export default function NlSchedule() {

  const tabClick = useCallback(() => {
    // window.scrollTo(0, 0);
  }, []);

  return (
    <div className="nl-schedules mb-3">
      <PageHeader title='Set Times' />
      <Tabs
        // defaultActiveKey="thursday"
        id="schedule-tabs"
        onSelect={tabClick}
      >
        <Tab eventKey="thursday" title="Thu">
          <ScheduleList useDay="thursday" />
        </Tab>
        <Tab eventKey="friday" title="Fri">
          <ScheduleList useDay="friday" />
        </Tab>
        <Tab eventKey="saturday" title="Sat">
          <ScheduleList useDay="saturday" />
        </Tab>
        <Tab eventKey="sunday" title="Sun">
          <ScheduleList useDay="sunday" />
        </Tab>
      </Tabs>
    </div>
  );

}
